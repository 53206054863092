import React from "react";
import { Divider, Button, Form } from "semantic-ui-react";
import {
  TableBIM,
  TableFlow,
  TableAudit,
  AddNew,
} from "../../components/index";

const CRUD = ({ header, add, table, subHeader }) => {
  function returnTable() {
    switch (table.type) {
      case "TableFlow":
        return (
          <TableFlow
            header_array={table.header_array}
            content={table.content}
            handleRemoveEntry={table.handleRemoveEntry}
            handleUpdateEntry={table.handleUpdateEntry}
          />
        );

        break;
      case "Audits":
        return (
          <TableAudit
            header_array={table.header_array}
            content={table.content}
            handleRemoveEntry={table.handleRemoveEntry}
            handleUpdateEntry={table.handleUpdateEntry}
          />
        );

        break;

      default:
        return (
          <TableBIM
            header_array={table.header_array}
            content={table.content}
            handleRemoveEntry={table.handleRemoveEntry}
          />
        );
        break;
    }
  }
  return (
    <div>
      <h1>{header}</h1>
      <Divider />
      <h2>{subHeader || "Add"}</h2>
      <AddNew
        placeholder={add.placeholder}
        value={add.value}
        onChange={add.onChange}
        add={add.add}
        button_text={add.button_text}
      />
      <Divider />
      {returnTable()}
    </div>
  );
};

export { CRUD };
