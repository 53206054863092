import React from "react";
import { Divider, Dropdown, Form } from "semantic-ui-react";

// import { AddNew } from "../../components/index";
import { AddNew } from "../../components/index";
import { TableOptions } from "../../components/index";

const OptionsCRUD = ({ header, add, table, subHeader, dropdown }) => {
  return (
    <div>
      <h1>{header}</h1>
      <Divider />
      <h2>{subHeader || "Add"}</h2>
      <Dropdown
        floating
        placeholder={dropdown.placeholder}
        search
        selection
        defaultValue={dropdown.dropdown_selected}
        selectedLabel={dropdown.dropdown_selected}
        options={dropdown.data.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }))}
        onChange={(e, { value }) => dropdown.select_function(value)}
      />
      <AddNew
        placeholder={add.placeholder}
        value={add.value}
        onChange={add.onChange}
        add={add.add}
        button_text={add.button_text}
      />

      <Divider />
      <TableOptions
        header_array={table.header_array}
        content={table.content}
        handleRemoveEntry={table.handleRemoveEntry}
        handleUpdateEntry={table.handleUpdateEntry}
      />
    </div>
  );
};

export { OptionsCRUD };
