import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CRUD, OptionsCRUD } from "../../../collections";
import { addOption, fetchOptions, removeOption } from "../../../actions";

import Layout from "../../../components/layout";
import Container from "../../../common/components/container";
import { Segment } from "semantic-ui-react";

import option_type from "../../../data/option_types.json";

const Index = () => {
  const dispatch = useDispatch();
  const [option_name, update_option_name] = useState("");
  const [option_type_selected, update_option_type_selected] = useState(null);

  const [loading, update_loading] = useState(true);

  const options = useSelector((state) => state.options);

  useEffect(() => {
    dispatch(fetchOptions());
    update_loading(false);
  }, []);

  function handleAddOption() {
    dispatch(addOption({ name: option_name, type: option_type_selected }));
    update_option_name("");
  }

  function handleRemoveOption(practice) {
    dispatch(removeOption(practice));
  }

  function handleUpdateDropdown(value) {
    console.log(value);
    update_option_type_selected(value);
  }

  return (
    <Layout>
      <Container>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <OptionsCRUD
            header={"Options"}
            add={{
              placeholder: "Option Name",
              value: option_name,
              onChange: update_option_name,
              add: handleAddOption,
              button_text: "Add Option",
            }}
            dropdown={{
              data: option_type,
              select_function: handleUpdateDropdown,
              dropdown_selected: option_type_selected,
            }}
            table={{
              header_array: ["Name", "ID", "Created", "Remove?"],
              content: options,
              handleRemoveEntry: handleRemoveOption,
            }}
          />
        )}
      </Container>
    </Layout>
  );
};

export default Index;
